import React from "react";
import { useSpring } from "react-spring";
import { Row, Button, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {
  AwsActivateProgram,
  ButtonLink,
  Link,
  TheHow,
  Hero,
  Seo,
  SocialProof,
  Checkout,
} from "../components";
import VisibilitySensor from "react-visibility-sensor";
// import AdSense from "react-adsense";
import { trackEvent } from "../components/googleAnalytics";

import screenshot from "../images/screenshot.png";
import { Trans, useTranslation } from "react-i18next";
import NewsletterForm from "../components/newsletterForm/newsletterForm";
import useHelpline from "../hooks/useHelpline";
import SocialMediaIcons from "../components/socialMediaIcons/socialMediaIcons";

const images = {
  mission: require("../images/mission.svg"),
  insights: require("../images/insights.svg"),
  openPositions: require("../images/openPositions.svg"),
};

const Start = ({ routeKey }) => {
  const [, setY] = useSpring(() => ({ y: 0 }));
  const [t] = useTranslation();
  const { isHelpline } = useHelpline();

  let isStopped = false;
  const onWheel = () => {
    isStopped = true;
    window.removeEventListener("wheel", onWheel);
  };

  const trackFeaturesImpression = (isVisible) => {
    if (isVisible) {
      trackEvent({
        category: "Content",
        action: "Impression",
        label: "Features",
        nonInteraction: true,
      });
    }
  };

  const trackFAQ = () => {
    trackEvent({
      category: "Content",
      action: "Click",
      label: "faq",
    });
  };
  const trackContact = () => {
    trackEvent({
      category: "Content",
      action: "Click",
      label: "contact",
    });
  };

  const scrollToTarget = () => {
    trackEvent({
      category: "Content",
      action: "Click",
      label: "lets_start",
    });

    const element = document.getElementById("imageselector");
    const value = window.scrollY + element.getBoundingClientRect().top;

    window.addEventListener("wheel", onWheel);

    setY({
      y: value,
      reset: true,
      from: { y: window.scrollY },
      onRest: () => {
        isStopped = false;
        window.removeEventListener("wheel", onWheel);
      },
      onFrame: (props) => {
        if (!isStopped) {
          window.scroll(0, props.y);
        }
      },
    });
  };

  return (
    <>
      <Seo routeKey={routeKey} />
      <Hero />
      <SocialProof />
      <AwsActivateProgram />
      <Container>
        <Row className="py-5 justify-content-center">
          <Col>
            <h3 id="imageselector" className="text-center">
              {t("screenStart.imageSelector1")}
            </h3>
            <hr className="w-25 mb-5" />
            {isHelpline ? (
              <Checkout />
            ) : (
              <div
                className="alert alert-warning mb-5 text-center"
                role="alert"
              >
                <Trans i18nKey="paused.info" />
                <br />
                <br />
                <NewsletterForm />
                <br />
                <div className="flex align-items-center">
                  <Trans i18nKey="paused.social" />
                  <SocialMediaIcons />
                </div>
              </div>
            )}
            {/* <Checkout /> */}
          </Col>
        </Row>
        <Row className="py-5 justify-content-center">
          <VisibilitySensor
            minTopValue={300}
            partialVisibility
            delayedCall
            onChange={trackFeaturesImpression}
          >
            <Col md={10} lg={8}>
              <div className="pb-5 mb-5">
                <h2 className="text-center">
                  {t("screenStart.whatDoYouGetHeadline")}
                </h2>
                <hr className="w-25 mb-5" />
                <Row className="align-items-center text-center text-md-left">
                  <Col xs={12} md={6} className="mb-5">
                    <img
                      className="w-75 d-block mx-auto"
                      src={screenshot}
                      alt={t("screenStart.screenShotAlt")}
                    />
                  </Col>
                  <Col>
                    <p className="lead">
                      <Trans i18nKey="screenStart.whatDoYouGet1">
                        Millions of porn videos are on the internet
                        <strong>without the consens</strong> of those affected.
                        Our face recognition technology will find you within
                        seconds.
                      </Trans>
                    </p>
                    <p className="lead">
                      <Trans i18nKey="screenStart.whatDoYouGet2">
                        After Uploading a photo of your face our image search
                        engine will screen the biggest adult websites to find
                        matches.
                      </Trans>
                    </p>
                    <p className="lead">
                      <Trans i18nKey="screenStart.whatDoYouGet3">
                        The results show the <strong>most exact matches</strong>
                        so you can <strong>directly check and report</strong> if
                        necessary.
                      </Trans>
                    </p>
                  </Col>
                </Row>
              </div>
              <TheHow />
            </Col>
          </VisibilitySensor>
        </Row>
        {/* <Row className="py-5 justify-content-center">
          <Col>
            <h2 className="text-center">{t("screenStart.imageSelector2")}</h2>
            <hr className="w-25 mb-5" />
            <Checkout />
          </Col>
        </Row> */}
      </Container>
      <div className="gradient my-5 py-5">
        <Container>
          <Row className="py-5 justify-content-center">
            <Col md={10} lg={6}>
              <Row className="align-items-center justify-content-center">
                <Col md={4}>
                  <img
                    src={images.mission}
                    className="d-block mx-auto mb-4 w-100px"
                    alt={t("missionImageAlt")}
                  />
                </Col>
                <Col md={8} className="text-center text-md-left">
                  <h3>{t("screenStart.missionHeadline")}</h3>
                  <p className="lead mb-4">
                    <Trans i18nKey="screenStart.missionContent">
                      Our mission is to <strong>empower people</strong> to
                      protect their
                      <strong>human rights</strong>
                      and preserve <strong>their dignity</strong> in the digital
                      space.
                    </Trans>
                  </p>
                  <Link className="link-lg text-light" to="ourMission">
                    {t("screenStart.missionReadMore")}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Row>
                <Col md={4} />
                <Col md={8}>
                  <hr className="border-light my-5" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="py-5 justify-content-center">
            <Col md={10} lg={6}>
              <Row className="align-items-center justify-content-center">
                <Col md={4}>
                  <img
                    src={images.insights}
                    className="d-block mx-auto mb-4 w-100px"
                    alt={t("insightsImageAlt")}
                  />
                </Col>
                <Col md={8} className="text-center text-md-left">
                  <h3>{t("screenStart.insightsHeadline")}</h3>
                  <p className="lead mb-4">
                    <Trans i18nKey="screenStart.insightsContent">
                      Chances are that <strong>you are also affected</strong>,
                      like e.g. <strong>every 5th person</strong> in Australia
                      or
                      <strong>several VIP's</strong> all over the world.
                      <br />
                      <br />
                      Even if <strong>no real material</strong> of you exists at
                      all,
                      <strong>
                        it is possible due to new technologies like deepfake
                      </strong>
                      .
                    </Trans>
                  </p>
                  <Link className="link-lg text-light" to="insights">
                    {t("screenStart.insightsReadMore")}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Row>
                <Col md={4} />
                <Col md={8}>
                  <hr className="border-light my-5" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="py-5 justify-content-center">
            <Col md={10} lg={6}>
              <Row className="align-items-center justify-content-center">
                <Col md={4}>
                  <img
                    src={images.openPositions}
                    className="d-block mx-auto mb-4 w-100px"
                    alt={t("openpositionsImageAlt")}
                  />
                </Col>
                <Col md={8} className="text-center text-md-left">
                  <h3>{t("screenStart.openpositionsHeadline")}</h3>
                  <p className="lead mb-4">
                    <Trans i18nKey="screenStart.openpositionsContent">
                      If you want to support us in our mission to enable
                      everyone to defend their personal rights and preserve
                      their dignity in the digital space get in touch with us!
                    </Trans>
                  </p>
                  <Link className="link-lg text-light" to="openPositions">
                    {t("screenStart.openpositionsReadMore")}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="py-5 text-center">
          <Col>
            <h2>{t("screenStart.faqHeadline")}</h2>
            <Row className="mb-5 mt-5 justify-content-center">
              <Col md={4} lg={3} className="mb-4 mb-md-0">
                <ButtonLink
                  className="shadow-sm"
                  onClick={trackFAQ}
                  to="faq"
                  variant="secondary"
                  block
                >
                  {t("screenStart.goToFaq")}
                </ButtonLink>
              </Col>
              <Col md={4} lg={3}>
                <ButtonLink
                  className="shadow-sm"
                  to="contact"
                  onClick={trackContact}
                  variant="secondary"
                  block
                >
                  {t("screenStart.getInTouch")}
                </ButtonLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="w-25 border-bottom mx-auto" />
              </Col>
            </Row>
            <Row className="mt-5 mb-5 justify-content-center">
              <Col md={5} lg={4}>
                <Button
                  onClick={scrollToTarget}
                  className="shadow"
                  size="lg"
                  variant="primary"
                  block
                >
                  {t("screenStart.noLetsStart")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Start;
